import { isStage, Stage } from "./types";

export const getStage = (): Stage => {
  const stage = "beta";

  return isStage(stage) ? stage : "gamma-na";
};

export const getRumConfig = () => {
  let appID = "378ea438-2c82-47cb-bc37-12ef86fad3a1";
  let region = "us-east-1";

  if (!appID) {
    // Missing Katal patch, must be dev buid so use whatever
    appID = "decf4c32-74fd-44d4-b835-2d2818a40812";
  }

  if (!region) {
    // Missing Katal patch, must be dev buid so use whatever
    region = "us-east-1";
  }

  return {
    appID,
    region,
    appVersion: "1",
  };
};
