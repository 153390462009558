import { createContext } from "react";

/**
 * This decouples us from react router dom for getting the info from navigation
 * and lets us ensure the props are set correctly in the root routing logic
 */
interface NavigationContextItems {
  applicationId?: string;
  jobId?: string;
  appointmentType?: string;
}

export const NavigationContext = createContext<NavigationContextItems>({});
