import { useCallback, useContext } from "react";
import { RUMContext } from "../rum/rum-context";
import { NavigationContext } from "src/rum/navigation-context";

type HvhNheEventType = `hvhnhe_${"info" | "error"}_${string}`;
interface BaseRumEvent {
  // To allow prefix matching insights rules we for events to identify as info or error
  type: HvhNheEventType;
}

interface AuthRedirectEvent extends BaseRumEvent {
  type: "hvhnhe_info_auth_redirect";
  fromUrl: string;
}

interface GenericErrorPageHit extends BaseRumEvent {
  type: "hvhnhe_error_generic_error_page";
  error: unknown;
}

interface CodeScannedEvent extends BaseRumEvent {
  type: "hvhnhe_info_code_scanned";
  code_type: "full" | "token_only";
  qr_code_size: number; // From the 'version' of QR code indicating the size, [1..40]
}

export type HvhNheRumEvent = AuthRedirectEvent | GenericErrorPageHit | CodeScannedEvent;

interface HvhNhePageView {
  pageId:
    | "rvp-ingress"
    | "rvp-scan-code"
    | "enable-permission"
    | "device-test"
    | "active-call"
    | "call-ended"
    | "appointment-complete"
    | "block-incomplete-tasks"
    | "block-too-early"
    | "block-too-late"
    | "block-not-scheduled"
    | "block-no-agent"
    | "generic-error";
}

interface UseRumResult {
  /**
   * Record an event with metadata
   */
  recordRumEvent: (event: HvhNheRumEvent) => void;
  /**
   * Record a page view and attach metadata
   *
   * Attaching the metadata helps assess customer impact
   * as it is attached to all events on that page
   */
  recordPageView: (event: HvhNhePageView) => void;
}

/**
 * Automatically handles attaching job id and application id metadata to events if available
 *
 * This is done on a best-effort basis, some core RUM events like session_start will emit before this loads
 */
export const useRum = (): UseRumResult => {
  /**
   * Be really careful here, if we break this code we'll lose the info that tells us if the page is having issues!
   *
   * Avoid depending on anything else in the app or complex logic, assume anything can be null/undefined
   */
  const rumClient = useContext(RUMContext);
  const { jobId, applicationId, appointmentType } = useContext(NavigationContext);

  const recordRumEvent: (event: HvhNheRumEvent) => void = useCallback(
    ({ type, ...data }) => {
      rumClient?.recordEvent(type, data);
    },
    [rumClient]
  );

  const recordPageView: (event: HvhNhePageView) => void = useCallback(
    ({ pageId }) => {
      rumClient?.recordPageView({
        pageId,
        pageAttributes: {
          applicationId,
          appointmentType,
          jobId,
        },
      });
    },
    [rumClient, jobId, applicationId, appointmentType]
  );

  return { recordRumEvent, recordPageView };
};
