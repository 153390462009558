import React, { useEffect, useRef } from "react";
import { View } from "@amzn/stencil-react-components/layout";
import { H1, Text } from "@amzn/stencil-react-components/dist/submodules/text";
import { useTranslation } from "react-i18next";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";
import { useParams } from "react-router-dom";
import { getLocale } from "src/config/locale-config";
import { useRum } from "src/hooks/useRum";

const eventPageLoad = "page load of 'Complete your pre-hire tasks' page";

export const PreHireTaskList = () => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { recordPageView } = useRum();
  const { t } = useTranslation();
  const locale = getLocale();

  useEffect(() => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPageLoad);
    if (titleRef.current) titleRef.current.focus();
  }, []);

  useEffect(() => {
    recordPageView({ pageId: "block-incomplete-tasks" });
  }, [recordPageView]);

  return (
    <View data-testid="pre-hire-task-list">
      <H1
        id="page-title"
        ref={titleRef}
        tabIndex={-1}
        style={{ marginTop: 20, marginBottom: 20, fontSize: 24, lineHeight: 1.5 }}
      >
        {t("VNHE-PreHireTaskList-Title")}
      </H1>
      <View>
        <Text>{t("VNHE-PreHireTaskList-Description")}</Text>
        <ul>
          <li>
            <Text textAlign="left">{t("VNHE-PreHireTaskList-Task-1")}</Text>
          </li>
          <li>
            <Text textAlign="left">{t("VNHE-PreHireTaskList-Task-2")}</Text>
          </li>
          <li>
            <Text textAlign="left">{t("VNHE-PreHireTaskList-Task-3")}</Text>
          </li>
          <li>
            <Text textAlign="left">{t("VNHE-PreHireTaskList-Task-4")}</Text>
          </li>
        </ul>
      </View>
    </View>
  );
};
