import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { detectCountryFromDomain, getDefaultLocale, getLocale } from "src/config/locale-config";

const locale = getLocale();
const country = detectCountryFromDomain();
const defaultLocale = getDefaultLocale(country);

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../../translations/translation-${language}.puff.json`)
        .then((res) => {
          callback(null, res.resources);
        })
        .catch((error) => {
          callback(error, "not get the translation");
        });
    })
  )
  .init({
    load: "currentOnly",
    lng: locale,
    fallbackLng: defaultLocale,
    debug: true,
    react: {
      useSuspense: true,
    },
    keySeparator: false,
  });

export default i18n;
