import { initializeAmazonConnectWithCredential } from "./amazonConnect";
import { ConnectWidgetData } from "./types";

interface ConnectQueueFragmentProps {
  connectWidgetData: ConnectWidgetData;
}

export const ConnectQueueFragment = ({ connectWidgetData }: ConnectQueueFragmentProps) => {
  // "mockEncodedToken" is used as a flag to enable MSW, adding the condition to enable local testing
  if (process.env.NODE_ENV === "development" && !localStorage.getItem("mockEncodedToken")) {
    // Connect refuses to load on localhost
    return <></>;
  }

  initializeAmazonConnectWithCredential({
    connectSourceUrl: connectWidgetData.connectSourceUrl,
    elementId: connectWidgetData.elementId,
    snippetId: connectWidgetData.snippetId,
    contactAttributes: connectWidgetData.encodedToken,
  });
  return <span></span>;
};
