const STAGES = [
	"beta",
	"gamma",
	"gamma-na",
	"gamma-mx",
	"gamma-eu",
	"gamma-za",
	"gamma-fe",
	"gamma-in",
	"preprod",
	"preprod-na",
	"preprod-mx",
	"preprod-eu",
	"preprod-za",
	"preprod-fe",
	"preprod-in",
	"prod",
	"prod-na",
	"prod-mx",
	"prod-eu",
	"prod-za",
	"prod-fe",
	"prod-in",
	"dev",
] as const;

export type Stage = typeof STAGES[number];

export function isStage(stage: string): stage is Stage {
	return STAGES.includes(stage as Stage);
}

export enum SUPPORTED_COUNTRIES {
  NA = "NA",
  EU = "EU",
}

export enum MEDIA_ACCESS {
  VIDEO = "video",
  AUDIO = "audio",
  AUDIO_OUTPUT = "audio-output",
}

export type ConnectWidgetDataError = "TOO_EARLY" | "TOO_LATE" | "NOT_FOUND" | "INCOMPLETE_TASKS" | "INELIGIBLE";

export interface ConnectWidgetData {
  connectSourceUrl: string;
  elementId: string;
  snippetId: string;
  encodedToken: string;
  agendaPublicText?: string;
  estimatedWaitTimeSecondsMinimum?: number;
  estimatedWaitTimeSecondsMaximum?: number;
}

export interface ConnectWidgetErrorData {
  cause: ConnectWidgetDataError;
  appointmentStartTimestamp: string;
  displayReadyTimeSlot?: string;
  displayTimeZone?: string;
}

export interface ConnectWidget {
  data?: ConnectWidgetData;
  error?: ConnectWidgetErrorData;
}

export enum APP_STATE {
  INIT = "INIT",
  GET_PERMISSION = "GET_PERMISSION",
  WAITING_ROOM = "WAITING_ROOM",
  START_VNHE = "START_VNHE",
  ERROR = "ERROR",
  QR = "QR",
  START_RVP = "START_RVP",
}

export enum AppointmentType {
  RIGHT_TO_WORK = "RIGHT_TO_WORK",
  NEW_HIRE_EVENT = "NEW_HIRE_EVENT",
  INSTANT_NEW_HIRE_EVENT = "INSTANT_NEW_HIRE_EVENT",
}
