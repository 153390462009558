import { ConnectWidgetData } from "src/components/types";

// QR code format types
export enum QRCodeFormat {
  FULL = "full",
  TOKEN_ONLY = "token_only",
}

export interface DefaultWidgetSettings {
  connectSourceUrl: string;
  elementId: string;
  snippetId: string;
}

const parseJson = (s: string) => {
  let ret = null;
  try {
    ret = JSON.parse(s);
  } catch (error) {
    console.error(error);
    ret = null;
  }
  return ret;
};

const isValidFullQR = (qr: Record<string, string> | null) => {
  const keys = ["u", "e", "s", "t"];
  if (!qr || !Object.keys(qr)) return false;
  return keys.reduce((acc, key) => {
    if (!acc) return false;
    return key in qr;
  }, true);
};

const isValidTokenOnlyQR = (qr: Record<string, string> | null) => {
  if (!qr || !Object.keys(qr)) return false;
  return "t" in qr;
};

const remapFullQR = (qr: Record<string, string>): ConnectWidgetData => {
  return {
    connectSourceUrl: qr.u,
    elementId: qr.e,
    snippetId: qr.s,
    encodedToken: qr.t,
  };
};

const remapTokenOnlyQR = (qr: Record<string, string>, defaults: DefaultWidgetSettings): ConnectWidgetData => {
  return {
    ...defaults,
    encodedToken: qr.t,
  };
};

export interface QRResult {
  data: ConnectWidgetData;
  format: QRCodeFormat;
}

export const getQRWithMetadata = (s: string, defaults: DefaultWidgetSettings | null): QRResult | null => {
  const data = parseJson(s);
  
  // Try full format first
  if (isValidFullQR(data)) {
    return {
      data: remapFullQR(data),
      format: QRCodeFormat.FULL
    };
  }
  
  // Then try token-only format
  if (isValidTokenOnlyQR(data)) {
    if (!defaults) {
      console.error("No default widget settings provided for token-only QR code");
      return null;
    }
    return {
      data: remapTokenOnlyQR(data, defaults),
      format: QRCodeFormat.TOKEN_ONLY
    };
  }
  
  return null;
};
