export const initializeAmazonConnectWithCredential = ({
  connectSourceUrl,
  elementId,
  snippetId,
  contactAttributes,
}) => {
  (function (w, d, x, id) {
    var s = d.createElement("script");
    s.src = connectSourceUrl;
    s.async = 1;
    s.id = id;
    d.getElementsByTagName("head")[0].appendChild(s);
    w[x] =
      w[x] ||
      function () {
        (w[x].ac = w[x].ac || []).push(arguments);
      };
  })(window, document, "amazon_connect", elementId);
  amazon_connect("styles", {
    iconType: "VOICE",
    openChat: { color: "#ffffff", backgroundColor: "#123456" },
    closeChat: { color: "#ffffff", backgroundColor: "#123456" },
  });
  amazon_connect("webCallingCustomizationObject", {
    videoFilter: { backgroundBlur: { option: "ENABLED_OFF_BY_DEFAULT" } },
  });
  amazon_connect("snippetId", snippetId);
  amazon_connect("supportedMessagingContentTypes", [
    "text/plain",
    "text/markdown",
    "application/vnd.amazonaws.connect.message.interactive",
    "application/vnd.amazonaws.connect.message.interactive.response",
  ]);
  amazon_connect("customLaunchBehavior", {
    skipIconButtonAndAutoLaunch: true,
    alwaysHideWidgetButton: true,
  });
  amazon_connect("authenticate", function (callback) {
    callback(contactAttributes);
  });
};
