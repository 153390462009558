import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { detectCountryFromDomain } from "src/config/locale-config";
import { getRumConfig } from "src/components/utils";
import { isAtoZDomain } from "src/utils/check-domain";
export class AwsRumClient {
  private awsRum?: AwsRum;

  public getClient(): AwsRum | undefined {
    return this.awsRum;
  }

  public initialize() {
    if (!this.awsRum) {
      try {
        const { appID, region, appVersion } = getRumConfig();
        const applicationVersion = `${appVersion ?? "unknown"}-${detectCountryFromDomain() ?? "unknown"}`;

        const rumConfig: AwsRumConfig = {
          sessionSampleRate: 1,
          telemetries: ["errors", "performance", "http"],
          allowCookies: false,
          enableXRay: false,
          endpoint: new URL(
            `${isAtoZDomain ? "/hvh-app" : ""}/checklist/api/rum-proxy/appointments`,
            window.location.origin
          ).toString(),
          signing: false,
        };
        if (!appID) {
          console.error("Rum App ID not found");
          return;
        }
        this.awsRum = new AwsRum(appID, applicationVersion, region ?? "us-west-2", rumConfig);
      } catch (error) {
        console.error(`Error creating AWS RUM client: ${error}`);
        return;
      }
    }
  }

  public constructor() {
    this.initialize();
  }
}
