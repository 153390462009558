import { ROOT_URL } from "src/constants/url-constants";
import { isAtoZDomain } from "src/utils/check-domain";

export const useRvpLoginUrl = () => {
  const params = new URLSearchParams(window.location.search);
  return `${window.location.origin}/app${params.get("bypasscorp") === "true" ? "?bypasscorp=true" : ""}#/myApplications`;
};

export const useChecklistUrl = ({ jobId, applicationId }: { jobId: string; applicationId: string }) => {
  const checklistUrl = new URL(`${window.location.origin}${isAtoZDomain ? "/hvh-app" : ""}/checklist/${jobId}/${applicationId}`);
  const params = new URLSearchParams(window.location.search);
  const bypasscorp = params.get("bypasscorp");
  if (bypasscorp) {
    checklistUrl.searchParams.append("bypasscorp", bypasscorp);
  }
  return checklistUrl.href;
};

export const useAppointmentUrl = ({
  jobId,
  applicationId,
  appointmentType,
}: {
  jobId: string;
  applicationId: string;
  appointmentType: string;
}) => {
  const appointmentUrl = new URL(`${window.location.origin}${ROOT_URL}/${jobId}/${applicationId}/${appointmentType}`);
  const params = new URLSearchParams(window.location.search);
  const bypasscorp = params.get("bypasscorp");
  if (bypasscorp) {
    appointmentUrl.searchParams.append("bypasscorp", bypasscorp);
  }
  return appointmentUrl.href;
};

export const useRvpStartUrl = () => {
  const rvpStartUrl = new URL(`${window.location.origin}${ROOT_URL}/remote-processing/ingress`);
  const params = new URLSearchParams(window.location.search);
  const bypasscorp = params.get("bypasscorp");
  if (bypasscorp) {
    rvpStartUrl.searchParams.append("bypasscorp", bypasscorp);
  }
  return rvpStartUrl.href;
};
