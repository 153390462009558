import { MSW_URL } from "./msw/handlers";
import {
  getAtoZChecklistAPIPrefix,
  isAtoZDomain,
} from "@amzn/ato-zhvh-app-common-util";

export const getAPIEndpoints = () => {
  if ((process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") && window.localStorage.getItem("hydra") !== "true") {
    return MSW_URL;
  } else {
    return `${window.location.origin}${isAtoZDomain() ? getAtoZChecklistAPIPrefix() : ""}/checklist`;
  }
};
