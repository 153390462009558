import { Button, ButtonVariant } from "@amzn/stencil-react-components/dist/submodules/button";
import { H1, P } from "@amzn/stencil-react-components/dist/submodules/text";
import { View } from "@amzn/stencil-react-components/layout";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getLocale } from "src/config/locale-config";
import { addEventMetric, MetricsWindow } from "src/dependencies/adobe-analytic";
import { useRum } from "src/hooks/useRum";

const eventPageLoad = "page load of 'enable camera and microphone' page";
const eventContinue = "click of 'Enable camera and microphone' button on enable camera and microphone page";

interface GetPermissionProps {
  onContinue: () => void;
}

export const GetPermission = ({ onContinue }: GetPermissionProps) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { recordPageView } = useRum();
  const locale = getLocale();
  const { t } = useTranslation();
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventPageLoad);
  }, [applicationId]);

  useEffect(() => {
    recordPageView({ pageId: "enable-permission" });
  }, [recordPageView]);

  useEffect(() => {
    if (titleRef.current && history.length > 2) titleRef.current.focus();
  }, []);

  const continueHandler = () => {
    addEventMetric(window as MetricsWindow, applicationId!, locale, eventContinue);
    onContinue();
  };

  return (
    <View>
      <H1 id="page-title" ref={titleRef} tabIndex={-1}>
        {t("VNHE-PreCheck-PermissionPage-Title")}
      </H1>
      <P>{t("VNHE-PreCheck-PermissionPage-Description")}</P>
      <Button data-testId="camera-and-mircrophone-btn" onClick={continueHandler} variant={ButtonVariant.Secondary}>
        {t("VNHE-PreCheck-PermissionPage-ButtonLabel")}
      </Button>
    </View>
  );
};
