import { DefaultWidgetSettings } from "src/utils/qr-helper";
import { Stage } from "src/components/types";

export const RVP_CONFIG: Record<Stage, DefaultWidgetSettings | null> = {
  beta: null,
  gamma: null,
  "gamma-na": {
    connectSourceUrl:
      "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js",
    elementId: "2cfb7f46-cb98-4c48-917d-92553c9a47ca",
    snippetId:
      "QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdIeTZIZGJiSkhnZEhnV1RqVU5yWnplQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNWGlFUExTT25Wd2RpSFMxVEFnRVFnQ3ZjZnJVRXdOSWZiZWVUVGF0VkUzTktaaXQrbHp4czI0M2hHaVA2V003SExqRjlmSDh4SkRjclVGcGc6OitnS2xJRFFGNFo1eis1N2tDS2FXVE10Y1dtYkJFbHVFYU1abHd0c1pIQUlqRFY5bEZDRnBpOWZVN0REVG8wN2Z5NVdIeC9FcXpMOU5ucUV5RVhHTC9IaUd4ZVpPRnBTb3NtNjBQMVU5Q25yOHVQY3lnc0JGY1NXRG5xTFNQZnpobVA3NDFZLzlUYWpXTmRWWkdHYkMveEtBOVo1UXdOND0=",
  },
  "gamma-mx": null,
  "gamma-eu": null,
  "gamma-za": null,
  "gamma-fe": null,
  "gamma-in": null,
  preprod: null,
  "preprod-na": {
    connectSourceUrl:
      "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js",
    elementId: "ca749fef-f12e-4e60-903a-b537ca808e94",
    snippetId:
      "QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdFcmRoQUdiMTRUK3VrYXkyTXYwQnVTQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNdGllZ281QWExNk4ya1VFTUFnRVFnQ3M4bU9KZTRFT3ZTVi9HS1NjTWxRV2V6MDY0Nk5rQThpV0MrcFJqb05paGxuVDgyRVBucytvdzVXOVE6OmlxR01KMldPS0lpUDBBdDhOVEduTitDV1dxZDV4NUYwdXl1WTFoWG8xdHpxTzZUZWUwYjlEYmRWMGJMSnNHU3Ntcy85YTk2N1RFWVhFNFVBVmNkcU1lT1YzbXl1Vzc0K3ZNWXpVaVM3VlZiL0xaRlBMRG05UkRHNSsyMGJ6cU9XZzJSWmoweGY3TVcrVlVTM3VGOCtKZGUyNmp0ZmZ4dz0=",
  },
  "preprod-mx": null,
  "preprod-eu": null,
  "preprod-za": null,
  "preprod-fe": null,
  "preprod-in": null,
  prod: null,
  "prod-na": {
    connectSourceUrl:
      "https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js",
    elementId: "ab9fb441-5f6b-4940-8fcd-a5b03e461e5e",
    snippetId:
      "QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdFK3RLVERIalpVeHV5b0ZLTG1PVEVrQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNNTJZTTZmOXJ5QnluMW0rTkFnRVFnQ3NySnhLUkVvckhXNkppWTZFL0xJVXUyUkMxQmY1Ylp4VFlYVW03NG1QWlpLNUM1WVFmRVIxcTRPYVE6OnhsWmFEOHlkM2R3djkrbjZ4Snk0VTkzZFM1Zk9YSlFmQnVZY0dSS2RkZ01vZ3lvLytzWHpEL2xpcE5NeVpKZDdHYks3Y3J6UnZrTkI2WlV3L3drS1hkS1BkVWFKS25WdWJTMEJ3T3VOUHorL0FoQXUvODhFemV3MDJlbTB6RGdCNkNMbEdGUzZEK3NiRXg1STY5bEorei9rWXhkVHV2bz0=",
  },
  "prod-mx": null,
  "prod-eu": null,
  "prod-za": null,
  "prod-fe": null,
  "prod-in": null,
  dev: null
}